import { Avatar, Box, Container, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { CoPresent } from '@mui/icons-material'
import './App.css'
function App() {
    const applicationList = useMemo(
        () => [
            {
                appName: 'DFU document tool',
                category: 'DFU',
                path: '/dfu-document',
                appIcon: (
                    <>
                        <img
                            src={process.env.PUBLIC_URL + '/images/dfu.png'}
                            alt="DFU"
                            style={{
                                maxHeight: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </>
                ),
                background: 'transparent',
            },
            {
                appName: 'Ticac document',
                category: 'TICAC',
                path: '/ticac',
                appIcon: (
                    <>
                        <img
                            src={process.env.PUBLIC_URL + '/images/ticac.png'}
                            alt="TICAC"
                            style={{
                                maxHeight: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </>
                ),
                background: 'transparent',
            },
        ],
        []
    )

    return (
        <Container>
            <Box sx={{ padding: '15px 0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '25px' }}>
                    <CoPresent sx={{ fontSize: '15rem', color: '#4e62ff' }} />
                    <Typography variant="h4" sx={{ color: '#fff' }}>
                        Policeadmin Portal
                    </Typography>
                </Box>
                <List
                    sx={{
                        padding: 0,
                        background: (theme) => theme.palette.background.default,
                        cursor: 'pointer',
                        '& .MuiListItem-root:hover': {
                            background: '#d0d0d0',
                        },
                    }}
                >
                    {applicationList.map((app, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                borderRadius: 0,
                                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ background: app.background }}>{app.appIcon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={app.appName}
                                secondary={`Category: ${app.category}`}
                                onClick={() => {
                                    window.location = `${process.env.PUBLIC_URL}${app.path}`
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Container>
    )
}

export default App
